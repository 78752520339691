"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.BlockAccordion = function BlockAccordion($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here
  var $accordions = $('.block-accordion'),
    _animSpeed = 275;

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Your initialisation code here
    initAccordions();
    return true;
  };

  /**
   * Triggers Events function ( Actions )
   * @public
   */
  var initAccordions = function initAccordions() {
    // Each Instances...
    $accordions.each(function () {
      var $accordion = $(this);
      $accordion.on('click', '.block-accordion-label', function (event) {
        event.preventDefault();
        var $row = $(this).parent(); // User click a row

        // If the clicked row is not(!) opened
        // We open it...
        if (!$row.hasClass('opened')) {
          // We close all currently opened Rows
          closeAllRows($accordion);

          // And open this one
          $row.addClass('opened');
          $row.find('.block-accordion-content').stop().slideDown(_animSpeed);
        } else {
          closeAllRows($accordion);
        }
      });
      $accordion.on('click', '.block-accordion-row a', function (event) {
        event.stopPropagation();
      });
    });
    var accordionsContents = document.querySelectorAll('.block-accordion-content');
    if (accordionsContents.length) {
      accordionsContents.forEach(function (accordionContent) {
        // Sélectionnez toutes les balises <img> dans le document
        var figures = accordionContent.getElementsByTagName('figure');

        // Parcourez chaque balise <img>
        for (var i = 0; i < figures.length; i++) {
          var figure = figures[i];
          var img = figure.getElementsByTagName('img')[0];

          // Créez un nouvel élément <a>
          var link = document.createElement('a');

          // Attribuez à l'élément <a> l'attribut href avec la valeur du chemin d'accès de l'image
          link.href = img.src;
          link.setAttribute('data-fslightbox', 'gallery');

          // Créez un nouvel élément <img> à l'intérieur de l'élément <a>
          var newImg = document.createElement('img');
          newImg.src = img.src;
          newImg.alt = img.alt; // Copiez l'attribut alt de l'image si nécessaire

          // Remplacez la balise <img> par l'élément <a> dans le DOM
          figure.parentNode.replaceChild(link, figure);
          link.appendChild(newImg);
        }
      });
      refreshFsLightbox();
    }
  };

  /**
   * Close all opened Rows
   * @public
   */
  var closeAllRows = function closeAllRows($accordion) {
    var $rows = $('.block-accordion-row', $accordion);
    $.each($rows, function (index, val) {
      var $row = $(this);
      $row.find('.block-accordion-content').stop().slideUp(_animSpeed, function () {
        $row.removeClass('opened');
      });
    });
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);