"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App($) {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param {Object} config.env     Current server environment
   * @param {Object} config.csrf    Security token to submit with forms
   * @param {Object} config.locale  Current locale short code
   * @param {Object} config.device  Device detection based on browser signature
   * @param {Object} config.preview Page is shown through live preview mode
   */
  var config = {
    env: 'production',
    csrf: null,
    locale: 'en',
    device: 'desktop',
    preview: false
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Store application settings
    options = options || {};
    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }

    // Initialize child classes
    if (_typeof(site.Track) === 'object') {
      site.Track.init();
    }

    // Initialize child classes
    if (_typeof(site.MainNav) === 'object') {
      site.MainNav.init();
    }

    // Initialize child classes
    if (_typeof(site.BlockAccordion) === 'object') {
      site.BlockAccordion.init();
    }

    // Initialize child classes
    if (_typeof(site.FindSchool) === 'object') {
      site.FindSchool.init();
    }

    // Initialize child classes
    if (_typeof(site.SchoolDetails) === 'object') {
      site.SchoolDetails.init();
    }

    // Initialize child classes
    if (_typeof(site.ContactMap) === 'object') {
      site.ContactMap.init();
    }

    // Initialize child classes
    if (_typeof(site.SchoolMap) === 'object') {
      site.SchoolMap.init();
    }

    // Initialize child classes
    if (_typeof(site.ContactForm) === 'object') {
      site.ContactForm.init();
    }

    // Initialize child classes
    if (_typeof(site.FAQ) === 'object') {
      site.FAQ.init();
    }

    // Initialize child classes
    if (_typeof(site.Sitemap) === 'object') {
      site.Sitemap.init();
    }

    // Initialize child classes
    if (_typeof(site.Transp) === 'object') {
      site.Transp.init();
    }

    // Initialize child classes
    if (_typeof(site.Recours) === 'object') {
      site.Recours.init();
    }

    // Initialize child classes
    if (_typeof(site.Search) === 'object') {
      site.Search.init();
    }

    // Initialize child classes
    if (_typeof(site.HomeSectionReducer) === 'object') {
      site.HomeSectionReducer.init();
    }

    // Initialize child classes
    if (_typeof(site.HomeSlider) === 'object') {
      site.HomeSlider.init();
    }
    var b = document.documentElement;
    b.setAttribute('data-useragent', navigator.userAgent);
    b.setAttribute('data-platform', navigator.platform);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}(jQuery);