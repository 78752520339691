"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.HomeSlider = function HomeSlider($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $homeSlider = document.querySelector('.homeSlider');
    if ($homeSlider) {
      var flkty = new Flickity($homeSlider, {
        // options
        cellAlign: 'left',
        contain: true,
        wrapAround: true,
        autoPlay: $homeSlider.dataset.speed * 1000
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);