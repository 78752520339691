"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.Transp = function Transp($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var _form = $('.main-form.transp-form');
    if (_form.length) {
      $('#numero').on('focus', function () {
        $('#input-info').slideDown();
      }).on('focusout', function () {
        $('#input-info').slideUp();
      });
      _form.submit(function (e) {
        e.preventDefault();
        var numero = _form.find('input[name=numero]').val(),
          zipcode = _form.find('input[name=zipcode]').val(),
          noCiv = _form.find('input[name=noCiv]').val();
        if (numero == '') {
          alert('Veuillez précisez le numéro de fiche.');
        } else if (zipcode == '') {
          alert('Veuillez précisez le code postal de votre demeure.');
        } else if (noCiv == '') {
          alert('Veuillez précisez le numéro civique de votre demeure.');
        } else {
          trouverParcours(numero, zipcode, noCiv);
        }
      });
    }
    $('.overlay-background').click(function () {
      $('.overlay-background').fadeOut();
      $('.overlay').fadeOut(400);
      $('body').css('overflow-y', 'auto');
    });
    function trouverParcours(nofiche, codePostal, noCiv) {
      if (nofiche != '') {
        $('#loading').fadeIn();
        var xmlhttp = false;
        try {
          var xmlhttp = new XMLHttpRequest();
        } catch (E) {
          xmlhttp = false;
        }
        xmlhttp.open('GET', CSRDN_SERVER + '__coordonneestransp.asp?rech=parc&nofiche=' + nofiche + '&codePostal=' + codePostal + '&noCiv=' + noCiv, true);
        xmlhttp.onreadystatechange = function () {
          if (xmlhttp.readyState == 4) {
            $('#loading').fadeOut();
            var rep = xmlhttp.responseText;
            rep = rep.replace('images/bt_imp.gif', PRINT_URL).replace('img/pdf.gif', PDF_URL).replace('href="imp_coor.asp"', 'onClick="javascript:openPP();"');
            $('.overlay').empty().html(rep);
            $('.overlay-background').fadeIn();
            $('.overlay').fadeIn(400);
            $('body').css('overflow-y', 'hidden');
          }
        };
        xmlhttp.send(null);
      }
    }
    function openPP() {
      var yourDOCTYPE = "<!DOCTYPE html>"; // your doctype declaration
      var printPreview = window.open('about:blank', 'print_preview', "resizable=yes,scrollbars=yes,status=yes");
      var printDocument = printPreview.document;
      printDocument.open();
      printDocument.write(yourDOCTYPE + "<html>" + $('.overlay')[0].innerHTML + "<script>window.print();</script></html>");
      printDocument.close();
    }
    window.openPP = openPP;
    return true;
  };

  /**
   * Initializes the class.
   * @public
   */
  var PrintPage = function PrintPage() {};

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);