window.csrdn = window.csrdn || {};

/**
 * Main application class.
 * @class App
 * @static
 */
csrdn.App = function App($) {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param {Object} config.env     Current server environment
   * @param {Object} config.csrf    Security token to submit with forms
   * @param {Object} config.locale  Current locale short code
   * @param {Object} config.device  Device detection based on browser signature
   * @param {Object} config.preview Page is shown through live preview mode
   */
  var config = {
    env: 'production',
    csrf: null,
    locale: 'en',
    device: 'desktop',
    preview: false
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function (options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Store application settings
    options = options || {};
    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }

    // Initialize child classes
    if (typeof csrdn.Track === 'object') {
      csrdn.Track.init();
    }

    // Initialize child classes
    if (typeof csrdn.MainNav === 'object') {
      csrdn.MainNav.init();
    }

    // Initialize child classes
    if (typeof csrdn.BlockAccordion === 'object') {
      csrdn.BlockAccordion.init();
    }

    // Initialize child classes
    if (typeof csrdn.FindSchool === 'object') {
      csrdn.FindSchool.init();
    }

    // Initialize child classes
    if (typeof csrdn.SchoolDetails === 'object') {
      csrdn.SchoolDetails.init();
    }

    // Initialize child classes
    if (typeof csrdn.ContactMap === 'object') {
      csrdn.ContactMap.init();
    }

    // Initialize child classes
    if (typeof csrdn.SchoolMap === 'object') {
      csrdn.SchoolMap.init();
    }

    // Initialize child classes
    if (typeof csrdn.ContactForm === 'object') {
      csrdn.ContactForm.init();
    }

    // Initialize child classes
    if (typeof csrdn.FAQ === 'object') {
      csrdn.FAQ.init();
    }

    // Initialize child classes
    if (typeof csrdn.Sitemap === 'object') {
      csrdn.Sitemap.init();
    }

    // Initialize child classes
    if (typeof csrdn.Transp === 'object') {
      csrdn.Transp.init();
    }

    // Initialize child classes
    if (typeof csrdn.Recours === 'object') {
      csrdn.Recours.init();
    }

    // Initialize child classes
    if (typeof csrdn.Search === 'object') {
      csrdn.Search.init();
    }

    // Initialize child classes
    if (typeof csrdn.HomeSectionReducer === 'object') {
      csrdn.HomeSectionReducer.init();
    }

    // Initialize child classes
    if (typeof csrdn.HomeSlider === 'object') {
      csrdn.HomeSlider.init();
    }
    var b = document.documentElement;
    b.setAttribute('data-useragent', navigator.userAgent);
    b.setAttribute('data-platform', navigator.platform);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function (option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}(jQuery);