"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.Recours = function Recours($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if (localStorage.getItem('recoursState') != 'hidden') {

      // $('.recours-collectif-wrapper').show();
    }
    $('.recours-collectif .close').click(function () {
      $('.recours-collectif-wrapper').slideUp();
      localStorage.setItem('recoursState', 'hidden');
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);