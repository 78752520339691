"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.SchoolMap = function SchoolMap($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($('#schoolGoogleMap').length) {
      var mapOptions = prepareMap();
      var mapElement = document.getElementById('schoolGoogleMap');

      // Create the Google Map using our element and options defined above
      var map = new google.maps.Map(mapElement, mapOptions);

      // Let's also add a marker while we're at it
      var position = SCHOOL_POSITION.split(',');
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(position[0], position[1]),
        map: map,
        icon: '/assets/images/google-map-marker-1.png'
      });

      //Resize Function
      google.maps.event.addDomListener(window, "resize", function () {
        var center = map.getCenter();
        google.maps.event.trigger(map, "resize");
        map.setCenter(center);
      });
    }
    return true;
  };

  /**
   * Prepare the map
   *
   * @public
   */
  var prepareMap = function prepareMap() {
    var position = SCHOOL_POSITION.split(',');
    var mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: 14,
      disableDefaultUI: true,
      // The latitude and longitude to center the map (always required)
      center: new google.maps.LatLng(position[0], position[1]),
      // New York

      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [{
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{
          "color": "#444444"
        }]
      }, {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
          "color": "#ffffff"
        }]
      }, {
        "featureType": "landscape.natural.landcover",
        "elementType": "geometry.fill",
        "stylers": [{
          "visibility": "on"
        }]
      }, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{
          "saturation": -100
        }, {
          "lightness": 45
        }]
      }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#f4e0a9"
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{
          "visibility": "simplified"
        }]
      }, {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [{
          "color": "#a0a0a0"
        }]
      }, {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{
          "visibility": "off"
        }]
      }, {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{
          "color": "#a0e1fb"
        }, {
          "visibility": "on"
        }]
      }]
    };
    return mapOptions;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);