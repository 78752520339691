"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.FindSchool = function FindSchool($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here
  var _filterBarItem = $('.filterBarItem:not(.active)');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Prepare the map
   *
   * @public
   */
  var prepareMap = function prepareMap() {
    var mapOptions = {
      // How zoomed in you want the map to start at (always required)
      zoom: 14,
      disableDefaultUI: true,
      // The latitude and longitude to center the map (always required)
      center: new google.maps.LatLng(45.793724, -74.008060),
      // New York

      // How you would like to style the map.
      // This is where you would paste any style found on Snazzy Maps.
      styles: [{
        'featureType': 'administrative',
        'elementType': 'labels.text.fill',
        'stylers': [{
          'color': '#444444'
        }]
      }, {
        'featureType': 'landscape',
        'elementType': 'all',
        'stylers': [{
          'color': '#ffffff'
        }]
      }, {
        'featureType': 'landscape.natural.landcover',
        'elementType': 'geometry.fill',
        'stylers': [{
          'visibility': 'on'
        }]
      }, {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [{
          'visibility': 'off'
        }]
      }, {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [{
          'saturation': -100
        }, {
          'lightness': 45
        }]
      }, {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [{
          'color': '#f4e0a9'
        }]
      }, {
        'featureType': 'road.highway',
        'elementType': 'all',
        'stylers': [{
          'visibility': 'simplified'
        }]
      }, {
        'featureType': 'road.highway',
        'elementType': 'geometry.fill',
        'stylers': [{
          'color': '#a0a0a0'
        }]
      }, {
        'featureType': 'road.arterial',
        'elementType': 'labels.icon',
        'stylers': [{
          'visibility': 'off'
        }]
      }, {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [{
          'visibility': 'off'
        }]
      }, {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [{
          'color': '#a0e1fb'
        }, {
          'visibility': 'on'
        }]
      }]
    };
    return mapOptions;
  };
  function rechercheParOrdre() {
    if (document.getElementById('ordre').value != '') {
      document.location.href = document.getElementById('ordre').value;
    }
  }
  function trouverBassin(year, ecole) {
    if (ecole != '') {
      var result = $.get(CSRDN_SERVER + '__trouver.asp?rech=bassin&anneescolaire=' + year + '&noecole=' + ecole).done(function (data) {
        var returnData = $(data).find('tr:not(.bassin_entete)');
        $('#schoolListTable tbody').empty().html(returnData);
      });
    }
  }
  function rechercheParAdresse() {
    trouverEcole(document.getElementById('anneescolaire').value, document.getElementById('municipalite').value, document.getElementById('rue').value, document.getElementById('numeroCivique').value);
  }
  function trouverMunicipalite(anneescolaire) {
    var result = $.get(CSRDN_SERVER + '__trouver.asp?rech=vrai&anneescolaire=' + anneescolaire).done(function (data) {
      $('#addressCity').find('option:not(:first-child)').remove().end().append($(data).find('option')).val(0);
    });
  }
  function trouverRue(anneescolaire, municipalite) {
    var result = $.get(CSRDN_SERVER + '__trouver.asp?rech=vrai&municipalite=' + municipalite + '&anneescolaire=' + anneescolaire).done(function (data) {
      $('#addressStreet').find('option:not(:disabled)').remove().end().append($(data).find('option')).val(0);
    });
  }
  function trouverEcole(anneescolaire, municipalite, rue, nocivique) {
    var result = $.get(CSRDN_SERVER + '__trouver.asp?rech=vrai&anneescolaire=' + anneescolaire + '&municipalite=' + municipalite + '&rue=' + rue + '&nocivique=' + nocivique).done(function (data) {
      var links = $(data).find('a u');
      var schoolNumber = new Array();
      $.each(links, function (i, v) {
        schoolNumber.push(v.innerText.split(/(\s+)/)[0]);
      });
      var d = {
        schoolNumber: schoolNumber[0] + ',' + schoolNumber[1]
      };
      $.ajax({
        url: window.location.href,
        data: d
      }).done(function (article) {
        $('.js-schoolList').empty().html(article);
        $('#loading').fadeOut();
      });
    });
  }
  function effacerResultats(effacerNoCivique, effacerRue) {
    document.getElementById('span_resultats').innerHTML = '';
    if (effacerNoCivique) {
      document.getElementById('numeroCivique').value = '';
    }
    if (effacerRue) {
      document.getElementById('rue').value = '';
    }
    document.getElementById('span_resultats').style.display = 'none';
    document.getElementById('span_resultats_bassin').style.display = 'none';
  }

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // change Display
    $('body').on('click', '.filterDisplay:not(.active):not(.disabled)', function () {
      $('.filterDisplay').removeClass('active');
      $(this).addClass('active');
      if ($(this).hasClass('map')) {
        $('.schoolListWrapper').hide();
        $('.schoolListMap').fadeIn();
        $('#loading').fadeIn();
        var category = $('.filterBarItem.active').data('href');
        var filter = $(this).data('value');
        $('.filterOrder').each(function (index) {
          var index = $(this);
          if (filter == index.data('value')) {
            index.addClass('active');
          } else {
            index.removeClass('active');
          }
        });
        $('.filterOrder[data-value="all"]').addClass('active');
        getDataForMap(category, filter);
      } else if ($(this).hasClass('list')) {
        $('.schoolListMap').hide();
        $('.schoolListWrapper').fadeIn();
      }
    });
    var getDataForMap = function getDataForMap(category, filter) {
      if (filter != 'all') {
        var data = {
          category: category,
          filter: filter,
          type: 'map'
        };
      } else {
        var data = {
          category: category,
          type: 'map'
        };
      }
      $.ajax({
        url: window.location.href,
        data: data
      }).done(function (data) {
        var markers = data.employees;
        var mapOptions = prepareMap();
        var mapElement = document.getElementById('googleMap');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);
        var bounds = new google.maps.LatLngBounds();

        // Let's also add a marker while we're at it
        for (var i = 0; i < markers.length; i++) {
          if (markers[i].coord) {
            var coord = markers[i].coord.split(',');
            var myLatLng = {
              lat: parseFloat(coord[0]),
              lng: parseFloat(coord[1])
            };
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(myLatLng),
              map: map,
              title: markers[i].name,
              icon: '/assets/images/google-map-marker-1.png',
              url: markers[i].url
            });
            bounds.extend(marker.getPosition());
            google.maps.event.addListener(marker, 'click', function () {
              window.location.href = this.url;
            });
          }
        }

        //Resize Function
        google.maps.event.addDomListener(window, 'resize', function () {
          var center = map.getCenter();
          google.maps.event.trigger(map, 'resize');
          map.setCenter(center);
        });
        map.fitBounds(bounds);
        $('#loading').fadeOut();
      }).fail(function (e) {
        console.log(e);
      });
    };
    var getDataForList = function getDataForList(category, filter) {
      if (filter != 'all') {
        var data = {
          category: category,
          filter: filter
        };
      } else {
        var data = {
          category: category
        };
      }
      $.ajax({
        url: window.location.href,
        data: data
      }).done(function (data) {
        $('.js-schoolList').empty().html(data);
        $('#loading').fadeOut();
      });
    };

    // Changer filter
    $('.subFilterWrapper .main-form').hide();
    $('body').on('click', '.filterBarItem:not(.active)', function () {
      $('.filterBarItem').removeClass('active');
      $(this).addClass('active');
      var href = $(this).data('href');
      $('.subFilterWrapper section').hide();
      $('.subFilterWrapper .' + href).fadeIn();
      if (href == 'filtersBassin') {
        $('.filterDisplay.map').addClass('disabled');
        if ($('.filterDisplay.map').hasClass('active')) {
          $('.filterDisplay').removeClass('active');
          $('.filterDisplay.list').addClass('active');
          $('.schoolListMap').hide();
        } else if ($('.filterDisplay.list').hasClass('active')) {
          $('.schoolListWrapper').hide();
        }
        $('.schoolListTable').fadeIn();
      } else if (href == 'filtersAddress') {
        $('.schoolListTable').hide();
        $('.schoolListWrapper').fadeIn();
        $('.filterDisplay.map').addClass('disabled').removeClass('active');
        $('.filterDisplay.list').addClass('active');
        $('.filterDisplay').each(function () {
          var index = $(this);
          if (index.hasClass('active')) {
            var filterDisplay = index.hasClass('map') ? 'map' : 'list';
            if (filterDisplay == 'map') {
              getDataForMap('filtersAddress', 'all');
            } else {
              getDataForList('filtersAddress', 'all');
            }
          }
        });
      } else {
        $('.filterDisplay.map').removeClass('disabled');
        if ($('.schoolListTable').css('display') == 'block') {
          $('.schoolListTable').hide();
          $('.schoolListWrapper').fadeIn();
        }
        $('.filterOrder[data-value=all]').click();
      }
    });
    $('#selectSchoolFilter').change(function () {
      var value = $(this).val(),
        selected = $('.filterBarItem[data-href=' + value + ']');
      selected.click();
    });
    $('.filterOrder').on('click', function () {
      $('#loading').fadeIn();
      var category = $('.filterBarItem.active').data('href');
      var filter = $(this).data('value');
      $('.filterOrder').each(function (index) {
        var index = $(this);
        if (filter == index.data('value')) {
          index.addClass('active');
        } else {
          index.removeClass('active');
        }
      });
      $('.filterDisplay').each(function () {
        var index = $(this);
        if (index.hasClass('active')) {
          var filterDisplay = index.hasClass('map') ? 'map' : 'list';
          if (filterDisplay == 'map') {
            getDataForMap(category, filter);
          } else {
            getDataForList(category, filter);
          }
        }
      });
    });
    if ($('.findFiltersWrapper').length) {
      trouverBassin($('#bassinYear').val(), $('#bassinSchool').val());
      $('#bassinSearch').on('click', function () {
        trouverBassin($('#bassinYear').val(), $('#bassinSchool').val());
      });
      trouverMunicipalite($('#addressYear').val());
      $('#addressYear').on('change', function () {
        trouverMunicipalite($('#addressYear').val());
      });
      $('#addressCity').on('change', function () {
        trouverRue($('#addressYear').val(), $('#addressCity').val());
      });
      $('#addressSearch').on('click', function () {
        if (!$('#addressCity') || !$('#addressStreet').val() || $('#addressNumber').val() == '') {
          alert("Veuillez remplir tous les champs.");
          return false;
        }
        $('#loading').fadeIn();
        trouverEcole($('#addressYear').val(), $('#addressCity').val(), $('#addressStreet').val(), $('#addressNumber').val());
      });
    }

    // Get AJAX year
    // $.ajax({
    //   url: 'http://aca.csrdn.qc.ca/www/__trouver.asp?rech=AnneeScolaire',
    //   data: data
    // }).done(function (data) {
    //   $('.js-schoolList').empty().html(data);
    //   $('#loading').fadeOut();
    // });

    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);