"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.FAQ = function FAQ($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($('.faq-section').length) {
      $("#subject").change(function () {
        var value = $(this).val();
        $('.faq-section').hide();
        $("#" + value).fadeIn();
      }).change();
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);