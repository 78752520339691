"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.MainNav = function MainNav($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here
  var $mainNavComponent = $('#main-nav'),
    $siteWrapper = $('#main-nav-site-wrapper'),
    $navLinks = $('#main-nav-menu'),
    $burgerBtn = $('#burger-btn');

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Your initialisation code here
    initMainNav();
    toggleNavBarFollow();
    return true;
  };

  /**
   * Triggers Events function ( Actions )
   * @public
   */
  var initMainNav = function initMainNav() {
    // On Main Nav Link Click
    $navLinks.on('click', '.main-nav-link', function (event) {
      if ($(event.target).closest('.main-nav-submenu').length) {
        return true;
      }
      var $link = $(this); // User click a link

      // Abort if the link does not(!) have any children links...
      if (!$link.hasClass('has-children')) {
        return true;
      }

      // Else we want to open a SubMenu
      event.preventDefault();

      // If the clicked link is not(!) opened
      // We open it...
      if (!$link.hasClass('opened')) {
        // We close all currently opened Submenus
        closeAllSubmenus();

        // And open this one
        $link.addClass('opened');
        $link.find('ul').stop().slideDown();
      } else {
        // We close all currently opened Submenus
        // Including this one
        closeAllSubmenus();
      }
    });

    // On Burger open menu (For Mobile State)
    $burgerBtn.on('click', function (event) {
      event.preventDefault();
      event.stopPropagation();
      $mainNavComponent.toggleClass('show');
    });

    // Close burger on click outsie
    $siteWrapper.on('click', function (event) {
      $mainNavComponent.removeClass('show');
    });
    $mainNavComponent.find('.iphone-close').on('click', function (event) {
      $mainNavComponent.removeClass('show');
    });
    $(window).scroll(function () {
      toggleNavBarFollow();
    });
  };

  /**
   * Close all opened Submenus
   * @public
   */
  var closeAllSubmenus = function closeAllSubmenus() {
    $navLinks.find('.opened').removeClass('opened').find('ul').stop().slideUp();
  };

  /**
   * Analysize and configure the behavior of the siedeBar Nav
   * to either Stick or Follow base on website content Overflow
   *
   * @public
   */
  var toggleNavBarFollow = function toggleNavBarFollow($link) {
    var navBar_height = $mainNavComponent.outerHeight(),
      site_height = $siteWrapper.outerHeight(),
      window_height = $(window).height(),
      scroll_pos = $(window).scrollTop();
    $mainNavComponent.css('position', navBar_height > window_height + 20 ? 'absolute' : 'fixed');
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);