"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.Track = function Track($) {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Bind event tracking to elements that have a data-track attribute.
    $(document).on('click', '[data-track]', function () {
      var obj = $(this).data('track');
      if (_typeof(obj) === 'object' && typeof obj.category === 'string' && typeof obj.action === 'string' && typeof obj.label === 'string') {
        sendEvent(obj.category, obj.action, obj.label);
      }
    });
    return true;
  };

  /**
   * Send a tracking event.
   * @public
   */
  var sendEvent = function sendEvent(category, action, label) {
    if (category && action && label && site.App.config('env') === 'production') {
      return ga('send', 'event', category, action, label);
    }
    return false;
  };

  /**
   * Send a page view event.
   * @public
   */
  var sendPage = function sendPage(url) {
    var parser;
    if (url === undefined || url === '') {
      url = location.pathname;
    } else {
      parser = document.createElement('a');
      parser.href = url;
      url = parser.pathname + parser.search;
    }
    if (site.App.config('env') === 'production') {
      return ga('send', 'pageview', url);
    }
    return false;
  };

  // Expose public methods & properties
  return {
    init: init,
    page: sendPage,
    event: sendEvent
  };
}(jQuery);