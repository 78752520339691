"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.HomeSectionReducer = function HomeSectionReducer() {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $homeSection = document.querySelector('.homeContent .customZone');
    if ($homeSection) {
      if ($homeSection.offsetHeight > 450) {
        $homeSection.classList.add('is-reduced');
        var $closeTrigger = $homeSection.querySelector('.show-more');
        if ($closeTrigger) {
          $closeTrigger.addEventListener('click', function () {
            $homeSection.classList.remove('is-reduced');
          });
        }
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();