"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.ContactForm = function ContactForm($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var _form = document.querySelector('form.main-form.contact-form');
    if (_form) {
      var schoolSite = _form.dataset.schoolSite;
      if (!schoolSite) {
        _form.addEventListener('submit', function (e) {
          var _subjectInput = document.querySelector('select[name="message[Sujet]"]');
          var subject = _subjectInput.value;
          var _selectedOption = _subjectInput.querySelector('option[value="' + subject + '"]');
          var emailValue = _selectedOption.getAttribute('data-email');
          var _toEmail = document.querySelector('input[name="message[toEmail]"]');
          if (_toEmail) {
            _toEmail.value = emailValue;
          }
          return true;
        });
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);