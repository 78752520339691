"use strict";

window.site = window.site || {};

/**
 * Header - Side Navigation - Scripts
 * @class Site
 * @static
 */
site.Sitemap = function Sitemap($) {
  'use strict';

  /**
   * jQuery elements
   * @private
   */

  // Your globale varaibles here

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($('.sitemap').length) {
      $('.grid-masonry').masonry({
        // set itemSelector so .grid-sizer is not used in layout
        itemSelector: '.grid-item',
        percentPosition: true
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}(jQuery);